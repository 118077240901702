(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/chat/assets/javascripts/chat.js') >= 0) return;  svs.modules.push('/components/components/chat/assets/javascripts/chat.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.Chat = function(data) {
    return {
      open: function(callback) {
        if (svs.core.detect.formfactor.desktop() && data.model.url) {
          var height = 720;
          var top = 0;
          if (window.screen) {
            if (window.screen.availHeight && window.screen.availHeight < 720) {
              height = window.screen.availHeight;
            }
            if (window.screen.availTop) {
              top = window.screen.availTop;
            }
          }
          window.open(
            data.model.url,
            'svs_chat',
            'height=' +
              height +
              ',width=510,top=' +
              top +
              ',menubar=0,toolbar=0,status=0'
          );
        } else if (!svs.core.detect.formfactor.desktop() && data.model.url) {
          window.location.href = data.model.url;
        }

        if (callback) {
          callback();
        }
      }
    };
  };
  svs.components.chat = new svs.components.Chat(svs.components.chat && svs.components.chat.data);
})(svs);


 })(window);